import Api from 'utils/api'
import i18n from 'utils/i18n'
import {produce} from 'immer'
import lib from 'utils/mrzParser.js'
import {
  TypeReadBus, SelfCheckinStepEnum
} from "components/shared/enums/global-enums.tsx";

const { ServiceBusClient } = require("@azure/service-bus");

export const selfCheckinSlice = ((set, get) => ({
  // Sita virtual env
  // locationId: 'OA-FAB-OTH-FLEXAPICUSTOMERINTEGRATION-01-FAB1OCA013',

  // Stephan unit
  // locationId: 'OA-SIN-OTH-SITASINGAPOREOFFICE-01-SIN1OAF036',
  
  // sita office
  locationId: 'OA-SIN-OTH-SITASINGAPOREOFFICE-01-SIN1WLF001',

  selfCheckinChoices: [],
  scannedElms: {},
  pendingPassScan: {},

  selfCheckinChoicesObj: {
    fr_id: null,
    first_name: null,
    last_name: null,
    bags: [],
    printBoardingPass: false,
    printBaggageTags: false,
    typeFor: SelfCheckinStepEnum.PASSENGER, 
    confirmed: false 
  },

  actions: {
    scanPassport: async (redirectFunc, overwrite=false) => {
      get().actions.startLoading();
      const api = new Api();
      let data = {location_id: get().locationId};
      data.overwrite = overwrite;
      api.scanPassport(data)
        .then((response) => {
          get().actions.readBusInfo(response.data, redirectFunc, TypeReadBus.SCAN, !overwrite);

          // get().actions.setSelfCheckinChoicesInit();
          // get().actions.setScannedElms("last_name", "TAN");
          // get().actions.setScannedElms("first_name", "KARAMOS");
          // redirectFunc(-1, SelfCheckinStepEnum.PASSENGER);

          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
        redirectFunc(-1);
        get().actions.stopLoading();
      });
    },

    stopScanPassport: async () => {
      const api = new Api();
      let data = {location_id: get().locationId};
      api.stopScanPassport(data)
        .then((response) => {
        })
      .catch((err) => {
      });
    },

    printElements: async (flightRecord, selfCheckinChoices, redirectFunc) => {
      const api = new Api();
      let data = {location_id: get().locationId};
      data.self_checkin_choices = selfCheckinChoices;
      api.printElements(data)
        .then((response) => {
          // get().actions.readBusInfo(response.data, redirectFunc, TypeReadBus.PRINT, false);
        })
      .catch((err) => {
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },

    readBusInfo: async (data, redirectFunc, typeRead, retry=true) => {

      const connectionString = data.connection_string;
      const topicName = data.topic_id;
      const subscriptionName = data.subscription_id;
      // const timeThreshold = data.time_threshold;
      let cptAttpt = -1;

      async function doIt() {
        // create a Service Bus client using the connection string to the Service Bus namespace
        const sbClient = new ServiceBusClient(connectionString);

        // createReceiver() can also be used to create a receiver for a queue.
        const receiver = sbClient.createReceiver(topicName, subscriptionName);

        // function to handle messages
        const myMessageHandler = async (messageReceived) => {
            console.log(messageReceived.body);
            retry = false;
            if (typeRead === TypeReadBus.SCAN){
              if (messageReceived?.body?.MrzData){

                let mrzData = messageReceived.body.MrzData.replace(/\n/g, "");
                mrzData = mrzData.replace(/\r/g, "");
                mrzData = mrzData.replace(/ /g, "");
                var data = lib.parse(mrzData);
                if (data.names.lastName){
                  get().actions.stopScanPassport();
                  
                  let fn = "";
                  let ln = data.names.lastName;

                  if (data.names.names?.length > 0) fn = data.names.names.join(" ");

                  let pConfirmInx = get().selfCheckinChoices.findIndex((el)=> (el.first_name === fn && el.last_name === ln));

                  if (pConfirmInx>=0){
                    let obj = {...get().selfCheckinChoices[pConfirmInx]}
                    obj.confirmed = true;
                    set(produce((state) => { 
                        state.selfCheckinChoices[pConfirmInx] = obj;
                    }));
                  }else{
                    get().actions.setSelfCheckinChoicesInit();
                    get().actions.setScannedElms("last_name", ln);
                    get().actions.setScannedElms("first_name", fn);
                  }

                  redirectFunc(-1, SelfCheckinStepEnum.PASSENGER);
                  get().actions.stopLoading();
                }
              }
            }

            if (typeRead === TypeReadBus.PRINT){
              if (messageReceived?.body?.isSuccess){
                // redirectFunc(1);
              }
            }
        };

        // function to handle any errors
        const myErrorHandler = async (error) => {
          cptAttpt++;
          if (retry && cptAttpt===0){
            get().actions.scanPassport(redirectFunc, true);
            get().actions.startTimeout(()=>{redirectFunc(-1, SelfCheckinStepEnum.IDLE)}, 30000);
          } 
        };

        // subscribe and specify the message and error handlers
        receiver.subscribe({
            processMessage: myMessageHandler,
            processError: myErrorHandler
        });

      }

      // call the main function
      doIt().catch((err) => {
        console.log(err);
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t("ERROR")})
         redirectFunc(-1);
      });
    }, 

    setPendingPassScan: (val)=>{
      set(produce((state) => { 
          state.pendingPassScan = val;
      }));
    },

    addSelfCheckinChoicesElm: ()=>{
      let arr = [...get().selfCheckinChoices];
      let obj = {...get().selfCheckinChoicesObj};
      arr.push(obj); 
      set(produce((state) => { 
          state.selfCheckinChoices = arr;
      }));
    },

    setSelfCheckinChoices: (index, item, val)=>{
      if (index >= get().selfCheckinChoices.length){
        get().actions.addSelfCheckinChoicesElm();
      }

      if (item === "bags"){
        let arr = [...get().selfCheckinChoices[index][item]];
        let obj = {weight: 10};
        if (val === 1) arr.push(obj); 
        else if (val === -1) arr.pop();  
        else if (val === -5) arr = [];  
        if (arr.length > 5) return; 
        val = arr;
      }

      set(produce((state) => { 
          state.selfCheckinChoices[index][item] = val;
      }));
    },

    setScannedElms: (item, val)=>{
      set(produce((state) => { 
          state.scannedElms[item] = val;
      }));
    },

    checkinChoicesBags: ()=>{
      let numbers = ["FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH"];
      let arr = [];
      get().selfCheckinChoices.forEach((sCC) => {
        sCC.bags.forEach((bag, index)=>{
          arr.push({order: numbers[index], name: sCC.first_name+" "+sCC.last_name, weight: bag.weight});
        }
      )});
      return arr;
    },

    setSelfCheckinChoicesInit: ()=>{
      set(produce((state) => { 
          state.selfCheckinChoices = [];
          state.scannedElms = {};
          state.pendingPassScan = null;

      }));
    }

  }

}))